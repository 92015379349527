import  { useCallback, useState, useContext } from "react"
import { utils, writeFileXLSX } from 'xlsx'
import { Actions } from "../../../Reducer/reducer"
import { CredentialsContext } from "../../../API/CredentialsContext"
import Table from "./Table"

const CheckReturnStatus = () => {

    const [gstin, setGstin] = useState([])
    const [data, setData] = useState([])
    const { state, dispatch } = useContext(CredentialsContext)
    const isCheckReturnButtonDisabled =
        gstin.length === 0 ||
        state.loading

    const handleGstinChange = (event) => {
        setGstin(event.target.value.split("\n").map(x => x.toUpperCase()))
    }

    const handleCheckReturn = async () => {
        for (const eachGst of gstin) {
            try {
                dispatch({ type: Actions.ShowLoading })
                const response = await fetch(`https://prod-1.e-waybill.in/api/gst/cfs?dealer=${eachGst}`)
                const responseData = await response.json()
                setData(prev => [...prev, { gstinNo: eachGst, responseData }])
            } catch (error) {
                console.error('Error in checking return of EWB:', error)
            } finally {
                dispatch({ type: Actions.HideLoading })
            }
        }
    }

    const downloadExcel = useCallback(() => {
        const newArray = data.slice(-gstin.length).map(({ gstinNo, responseData }) => ({
            gstinNo,
            status: responseData[0]?.period ? responseData[0].period : "ERROR"
        }))
        let dt = new Date()
        dt = dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate()
        const ws = utils.json_to_sheet(newArray)
        const wb = utils.book_new()
        utils.book_append_sheet(wb, ws, "Data")
        writeFileXLSX(wb, `ReturnStatus_${dt}.xlsx`)
    }, [data, gstin.length])

    return (
        <div className="py-2">
            <div className="row my-5">
                <div className="col-lg-3">
                    <h4>Check Return Filing Status</h4>
                    <textarea
                        className="form-control mb-2"
                        style={{ height: "400px" }}
                        name="gstin"
                        id="gstin"
                        placeholder="GSTIN One Per Line"
                        onChange={handleGstinChange}
                    />
                    <button
                        className="btn btn-primary mb-5"
                        onClick={handleCheckReturn}
                        disabled={isCheckReturnButtonDisabled}
                    >
                        Check Return
                    </button>
                </div>
                <div className="col-lg-9">
                    <div className="d-flex justify-content-end mb-3">
                        <button
                            className="btn btn-info"
                            onClick={downloadExcel}>
                            Download Excel
                        </button>
                    </div>
                    <div className="overflow-x-auto mb-3">
                      <Table data={data} gstin={gstin}/>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CheckReturnStatus