export const ExcelHeaderArray = [
    [
        {
            Consignor_Name: "Gowthaman",
            Consginor_Address: "Bangalore",
            Consignor_GSTIN: "20ARXPB1746A1ZQ",
            Consignor_Pincode: "560078",
            Consignee_Name: "Gowthaman",
            Consginee_Address: "Bangalore",
            Consignee_GSTIN: "20ARXPB1746A1ZQ",
            Consignee_Pincode: "560061",
            Invoice_No: "I001",
            Invoice_Date: "10/23/2017",
            Value_Of_Item: "10",
            HSNCode: "6304",
            Qty: "1",
            Units: "NOS"
        },
        {
            Consignor_Name: "Gowthaman",
            Consginor_Address: "Bangalore",
            Consignor_GSTIN: "20ARXPB1746A1ZQ",
            Consignor_Pincode: "560078",
            Consignee_Name: "Gowthaman",
            Consginee_Address: "Bangalore",
            Consignee_GSTIN: "20ARXPB1746A1ZQ",
            Consignee_Pincode: "560061",
            Invoice_No: "I001",
            Invoice_Date: "10/23/2017",
            Value_Of_Item: "10",
            HSNCode: "6305",
            Qty: "1",
            Units: "NOS"
        },
    ],
    [
        {
            wbn: "",
            client_name: "",
            gstDcn: "",
            current_city: "",
            current_state: "",
            vehicle_number: "",
            destination_city: "",
            destination_state: "",
            ewbn: "",
            current_pincode: ""
        }
    ]
]