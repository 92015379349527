import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faIndianRupeeSign } from "@fortawesome/free-solid-svg-icons"
import classNames from "classnames"
import { PrintTableHeader } from "../../../Data/printTableHeader"
import { ThreePulseCircles } from "../../../Animation/Animation"

const Table = ({ handleDeleteAllReqFromDB, requestsDB, handleDeleteEachReqFromDB }) => {
    return (
        <table className="table table-borderless table-striped">
            <thead>
                <tr className="text-uppercase table-dark">
                    {
                        PrintTableHeader.map((title, index) => {
                            return <th key={index}>{title}</th>
                        })
                    }
                    <th>
                        <button
                            type="button"
                            className="btn btn-sm btn-danger"
                            onClick={handleDeleteAllReqFromDB}>
                            Clear All
                        </button>
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {
                    requestsDB.map((request, index) => {
                        const Response = request.response
                        return (
                            <tr key={index} className={classNames({
                                'table-warning': !Response.valid
                            })}>
                                <td>{index + 1}</td>
                                <td>{Response.ewaybill}</td>
                                <td>
                                    {Response.consignorGst} <br />
                                    {Response.consignorAddr}
                                </td>
                                <td>
                                    {Response.consigneeGst} <br />
                                    {Response.consigneeAddr}
                                </td>
                                <td>
                                    {Response.invoiceNo} <br />
                                    {Response.invoiceDt} <br />
                                    <FontAwesomeIcon icon={faIndianRupeeSign} /> {Response.invoiceVal}
                                </td>
                                <td>
                                    {Response.transporterId}
                                </td>
                                <td>{new Date(Response.generatedAt).toDateString()}</td>
                                <td>{new Date(Response.validTill).toDateString()}</td>
                                <td
                                    className={classNames({
                                        'text-success': Response.valid,
                                        'text-danger': !Response.valid,
                                    })}
                                >
                                    {Response.valid ? "Valid EWaybill" : "Invalid/Expired EWaybill Or Not Assigned to you"}
                                </td>
                                <td>
                                    {
                                        request.status !== "ACTIVE" && request.status !== "IN_ACTIVE" && request.status !== "INVALID" ?
                                            <ThreePulseCircles /> :
                                            <a
                                                className="text-decoration-none"
                                                target="_blank"
                                                rel="noreferrer"
                                                href={`https://prod-1.e-waybill.in/api/e-waybill/print?ewaybill=${Response.ewaybill}&html-detail=true&prefer-html=true&fromPDFGenerator=true`}>
                                                Print
                                            </a>
                                    }
                                </td>
                                <td>
                                    <button
                                        type="button"
                                        className="btn btn-danger"
                                        onClick={handleDeleteEachReqFromDB.bind(this, request)}>
                                        Clear
                                    </button>
                                </td>
                            </tr>
                        )
                    })}
            </tbody>
        </table>
    )
}
export default Table