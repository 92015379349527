import classNames from "classnames"
import { ThreePulseCircles } from "../../../Animation/Animation"

const SubTable = ({ requestInDetail }) => {
    return (
        <div className="row">
            <h4>Waybills for {requestInDetail.requestId}</h4>
            {
                requestInDetail.request.consolidate && Object.keys(requestInDetail.response.errors).length === 0 &&
                <div className="bg-primary-subtle my-3 py-3 rounded">
                    Print Consolidated EWB
                    <span>
                        <a
                            href={`https://api.e-waybill.in/api/e-waybill/print-trip?request_id=${requestInDetail?.requestId}&cewb=${requestInDetail?.response?.consolidatedEWB}`}
                            className="text-decoration-none mx-1"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Here
                        </a>
                    </span>
                </div>
            }
            <div className="overflow-x-auto mb-3">
                <table className="table table-sm">
                    <thead className="table-secondary">
                        <tr className="text-uppercase">
                            <th>Ewaybill No</th>
                            <th>Valid till</th>
                            <th>Generated at</th>
                            <th>Valid For</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            requestInDetail.request.ewaybillNos.map((ewbNo, index) => {
                                const validTillDates = requestInDetail.response.validTill
                                const generatedAtDate = requestInDetail.response.generatedAt
                                const errors = requestInDetail.response.errors
                                return (
                                    <tr key={index}>
                                        <td>{ewbNo}</td>
                                        <td>{validTillDates[ewbNo] || ''}</td>
                                        <td>{validTillDates[ewbNo] && generatedAtDate?.slice(0, 10)}</td>
                                        <td>
                                            {
                                                validTillDates[ewbNo] &&
                                                `${Math.round(
                                                    (new Date(validTillDates[ewbNo]).getTime() - new Date(generatedAtDate).getTime()) / (1000 * 3600 * 24)
                                                )} days`
                                            } </td>
                                        <td className={classNames({
                                            "text-success": validTillDates[ewbNo],
                                            "text-danger": errors[ewbNo],
                                        })}>
                                            {
                                                requestInDetail.response.status !== "COMPLETED" ?
                                                    <ThreePulseCircles /> :
                                                    validTillDates[ewbNo] ?
                                                        "Updated" : errors[ewbNo] ||
                                                        <span className="text-danger">INVALID_EWAYBILL</span>
                                            }
                                        </td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
export default SubTable