const PartBMultiVehicleUpdateModal = ({ handleModalState, handleUpdateVehicleApi, index, request, modalState, isModalUpdateButtonDisabled }) => {
    return (
        <div className="modal fade" id={`addVehicleModal${index}`} tabIndex="-1" aria-labelledby="extendEWBModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title">Add Vehicle</h3>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="form-group col-6">
                                <label>Vehicle No (No Spaces)</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    value={modalState.vehicleNo}
                                    name="vehicleNo" id="vehicleNo"
                                    onChange={handleModalState}
                                    maxLength="10"
                                />
                            </div>
                            <div className="form-group col-6">
                                <label>QTY</label>
                                <input
                                    className="form-control"
                                    value={modalState.qty}
                                    type="number"
                                    name="qty"
                                    id="qty"
                                    onChange={handleModalState}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => handleUpdateVehicleApi(request, request.requestId)}
                            disabled={isModalUpdateButtonDisabled}
                            data-bs-dismiss="modal"
                        >
                            Update
                        </button>
                        <button type="button" className="btn btn-warning" data-bs-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default PartBMultiVehicleUpdateModal