import { useCallback, useContext, useEffect, useState } from "react"
import Papa from 'papaparse'
import { v4 as uuidv4 } from "uuid"
import { useIndexedDB } from "react-indexed-db-hook"
import { CredentialsContext } from "../../../API/CredentialsContext"
import MainTable from "./MainTable"
import SubTable from "./SubTable"
import { Actions } from "../../../Reducer/reducer"
import _ from "lodash"
import { utils, writeFileXLSX } from 'xlsx'

const Generate = () => {
    const [partAState, setPartAState] = useState({
        transactionType: "",
        loginType: "",
        credential: {},
        transportType: "ROAD",
        transporterGST: "",
        vehicleNo: "",
    })
    const [requests, setRequests] = useState([])
    const [requestsDB, setRequestsDB] = useState([])
    const [requestInDetail, setRequestInDetail] = useState()
    const [fileData, setFileData] = useState()
    const isLoginTypeConsignor = partAState.loginType === "TXP"
    const isModeOfTransportRoad = partAState.transportType === "ROAD"
    const { getAll, add, deleteRecord, update, clear } = useIndexedDB("RequestsForPartAV4")
    const { state, dispatch } = useContext(CredentialsContext)
    const users = state?.credentials || []
    const isGenerateButtonDisabled =
        partAState.loginType === "" ||
        partAState.transportMode === "" || (
            partAState.loginType === "TXP" &&
            partAState.transporterGST === "" &&
            partAState.transportType === "ROAD"
        ) ||
        Object.keys(partAState.credential).length === 0 ||
        document.getElementById("file").value === ""

    const handleState = (event) => {
        const { name, value } = event.target
        if (name === 'credential') {
            setPartAState(prev => (
                {
                    ...prev,
                    [name]: users.find(u => u.id === +value)
                }
            ))
        } else {
            setPartAState(prev => (
                {
                    ...prev,
                    [name]: value
                }
            ))
        }
    }

    const handleFileChange = async (event) => {
        await handleFileUpload(event.target.files[0])
    }

    const handleFileUpload = async (file) => {
        if (!file) {
            alert('Upload Correct Excel File')
            return
        }

        const apiUrl = 'https://prod-1.e-waybill.in/api/e-waybill/convertToCSV'

        const formData = new FormData()
        formData.append('file', file)

        const response = await fetch(apiUrl, {
            method: 'POST',
            body: formData,
        })

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`)
        }

        const data = await response.text()
        console.log('File uploaded successfully:')

        const fileData = Papa.parse(data)
        setFileData(fileData)
    }

    const commonParse = (row) => {
        const invalidConsigneeGST = isNaN(parseInt(row[6].substring(0, 2), 10))
        const colCount = row.length
        let qty = 0
        const exdTemplate = colCount >= 17
        if (exdTemplate) {
            qty = parseFloat(row[15])
        } else if (colCount >= 14) {
            qty = parseFloat(row[13])
        }

        const is_export = partAState.transactionType === 'EXPORT'
        const is_import = partAState.transactionType === 'IMPORT'
        const is_salesreturn = partAState.transactionType === 'SALES_RETURN'

        let docType = 'INV'
        if (colCount >= 16) {
            docType = row[15]
        }
        let goodsPurpose = 'SUPPLY'

        if (is_salesreturn) {
            docType = 'CHL'
            goodsPurpose = 'SALES_RETURN'
        }
        if (is_export) {
            docType = 'BIL'
            goodsPurpose = 'EXPORT'
        }
        if (is_import) {
            docType = 'BOE'
            goodsPurpose = 'IMPORT'
        }

        const consignorGST = row[2].toUpperCase().trim()
        const consigneeGST = (invalidConsigneeGST ? 'URP' : row[6]).toUpperCase().trim()
        const is_own_use = consigneeGST === consignorGST
        if (is_own_use) {
            docType = 'CHL'
            goodsPurpose = 'OWN_USE'
        }
        const consignorName = row[0]
        const consignorAddr = row[1].replace('\n', '').replace('\r', '').replace(/,/gi, ' ')
        const consigneeName = row[4]
        const consigneeAddress = row[5].replace('\n', '').replace('\r', '').replace(/,/gi, ' ')
        return {
            formId: row[8],
            billFrom: {
                gstNumber: consignorGST,
                name: consignorName,
                address: consignorAddr,
                pinCode: row[3].replace(/^0-9/gi, ''),
                state: consignorGST?.substring(0, 2) || '',
            },
            consignor: {
                gstNumber: 'URP',
                name: consignorName,
                address: consignorAddr,
                pinCode: is_import ? '999999' : row[3].replace(/^0-9/gi, ''),
            },
            billTo: {
                gstNumber: consigneeGST,
                name: consigneeName,
                address: consigneeAddress,
                pinCode: is_export ? '999999' : row[7].replace(/^0-9/gi, ''),
                state: is_export ? '99' : row[6].substring(0, 2),
            },
            consignee: {
                gstNumber: 'URP',
                name: consigneeName,
                address: consigneeAddress,
                pinCode: is_export ? '999999' : row[7].replace(/^0-9/gi, ''),
            },
            transporter: {
                id: partAState.loginType === 'TXP' ?
                    partAState.transporterGST?.trim() :
                    partAState.credential.gstNumber?.trim()?.toUpperCase(),
                docNo: '',
                docDt: null,
                transportMode: partAState.transportType?.trim(),
                vehicleNo: partAState.vehicleNo?.trim(),
            },
            invoice: {
                invoiceNo: row[8],
                invoiceDt: row[9],
                invoiceVal: row[10],
                goodsDirection: is_salesreturn || is_import ? 'IN' : 'OUT',
                purpose: goodsPurpose,
                docType: docType,
            },
            lineItems: [
                {
                    commName: '',
                    hsnCode: row[12].replace(/^0-9/gi, ''),
                    qty: qty,
                    cgstRate: 0,
                    cgst: 0,
                    sgstRate: 0,
                    sgst: 0,
                    igstRate: 0,
                    igst: 0,
                    gross: parseFloat(row[10]),
                    net: parseFloat(row[10]),
                    unit: 'NOS',
                },
            ],
        }
    }

    const handleGenerateEWB = async () => {
        try {
            dispatch({ type: Actions.ShowLoading })

            const allRowsAsEWB = fileData.data.map(eachArray => {
                return commonParse(eachArray)
            })

            const groupsBy = _.groupBy(allRowsAsEWB, (eachRow) => eachRow.invoice.invoiceNo)

            let ewaybills = []
            Object.keys(groupsBy).forEach(invNo => {
                let ewaybill = groupsBy[invNo][0]
                ewaybill.lineItems = groupsBy[invNo].map(item => item.lineItems[0])
                ewaybill.invoice.invoiceVal = _.sum(ewaybill.lineItems.map(li => li.net))
                ewaybills.push(ewaybill)
            })
            if (ewaybills.length > 0) {
                // group by invNo
                const payload = {
                    requestId: uuidv4(),
                    credentials: {
                        userName: partAState.credential.userName,
                        password: partAState.credential.password,
                        gstNumber: partAState.credential.gstNumber,
                        loginType: partAState.loginType
                    },
                    ewaybills: ewaybills
                }

                const requestId = uuidv4().replaceAll("-", "").substring(0, 11)
                const response = await fetch('https://prod-1.e-waybill.in/api/e-waybill/generate', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ ...payload, requestId })
                })
                
                if (response.ok) {
                    const data = await response.json()
                    const requestTime = new Date().getTime()
                    const requestTimeStr = new Date().toLocaleString('en-IN')
                    await add({
                        requestId,
                        requestTime,
                        requestTimeStr,
                        status: data.status,
                        response: data,
                        request: payload,
                        checkCount: 0
                    })
                    setRequests(r => ([...r, {
                        requestId,
                        requestTime,
                        requestTimeStr,
                        status: data.status,
                        response: data,
                        request: payload,
                        checkCount: 0
                    }]))
                } else {
                    console.error("Error in getting response")
                }
            }
        }
        catch (error) { console.error('Error in generating ewb:', error) }
    }

    const handleShowReqInDetail = (req) => {
        setRequestInDetail(req)
    }

    const handleDeleteEachReqFromDB = (entry) => {
        const isConfirmed = window.confirm("You won't be able to read this data again, are you sure?")
        if (isConfirmed) {
            deleteRecord(entry.requestId).then((event) => {
                alert(`${entry.requestId} was deleted`)
            })
            setRequests(r => r.filter(x => x.requestId !== entry.requestId))
        }
    }

    const handleDeleteAllReqFromDB = () => {
        const isConfirmed = window.confirm("You won't be able to read this data again, are you sure?")
        if (isConfirmed) {
            clear().then(() => {
                alert("Deleted all requests")
            })
        }
    }

    const handleDownloadExcel = () => {
        const newArray = requestInDetail?.request.ewaybills.map(({ consignor, consignee, invoice, lineItems }) => ({
            Consignor_Name: consignor.name,
            Consginor_Address: consignor.address,
            Consignor_GSTIN: consignor.gstNumber,
            Consignor_Pincode: consignor.pinCode,
            Consignee_Name: consignee.pinCode,
            Consginee_Address: consignee.address,
            Consignee_GSTIN: consignee.gstNumber,
            Consignee_Pincode: consignee.pinCode,
            Invoice_No: invoice.invoiceNo,
            Invoice_Date: invoice.invoiceDt,
            Value_Of_Item: invoice.invoiceVal,
            HSNCode: lineItems.map(item => item.hsnCode).join(', '),
            Qty: lineItems.reduce((total, currentItem) => total + currentItem.qty, 0),
            Units: lineItems[0].unit,
            Ewaybill_No: requestInDetail.response.generatedEWaybills[invoice.invoiceNo]
        }))
        const ws = utils.json_to_sheet(newArray)
        const wb = utils.book_new()
        utils.book_append_sheet(wb, ws, "Data")
        const date = new Date().toLocaleString()
        writeFileXLSX(wb, `${requestInDetail.requestId}${date}.xlsx`)
    }

    useEffect(() => {
        getAll().then(r => {
            r.sort((a, b) => (a.requestTime - b.requestTime))
            setRequests(r)
        })
    }, [getAll])

    useEffect(() => {
        const handleStatusCheck = async (r) => {
            if (r.status === 'COMPLETED' || r.checkCount > 10000) return r
            try {
                dispatch({ type: Actions.ShowLoading })
                const response = await fetch(`https://prod-1.e-waybill.in/api/e-waybill/status?request_id=${r.requestId}`)
                if (response.status === 200) {
                    const data = await response.json()
                    if (data.status === 'COMPLETED') {
                        update({ ...r, status: 'COMPLETED', response: data, checkCount: r.checkCount + 1 })
                        return { ...r, status: 'COMPLETED', response: data, checkCount: r.checkCount + 1 }
                    } else {
                        update({ ...r, status: data.status, response: data, checkCount: r.checkCount + 1 })
                        return { ...r, status: data.status, checkCount: r.checkCount + 1 }
                    }
                } else {
                    return { ...r }
                }
            }
            catch (error) { console.error('Error in checking status of EWB:', error) }
            finally { dispatch({ type: Actions.HideLoading }) }
            return r
        }

        (async () => {
            const r = await getAll()
            r.sort((a, b) => (a.requestTime - b.requestTime))
            setRequestsDB(r)
        })()

        const interval = setTimeout(() => {
            const checkForStatus = async () => {
                const results = await Promise.all(requests
                    .map(async (r) => {
                        return await handleStatusCheck(r)
                    }))
                setRequests(results)
            }
            checkForStatus().then(() => {
                console.info("check completed....")
            })
        }, 5000)
        return () => clearInterval(interval)
    }, [requests, getAll, dispatch, update])

    return (
        <div className="py-2">
            <div className="row">
                <div className="col-lg-3 m-3">
                    <h4>Generate E-Waybill</h4>
                    <div className="form-group mb-3">
                        <label htmlFor='loginType' className="mb-2">Login As</label>
                        <select
                            className="form-select"
                            id="loginType"
                            name="loginType"
                            onChange={handleState}
                        >
                            <option>Select</option>
                            <option value="TXP">Consignor</option>
                            <option value="TR">Transporter</option>
                        </select>
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="credential" className="mb-2">Credential</label>
                        <select
                            className="form-select"
                            id="credential"
                            name="credential"
                            onChange={handleState}
                        >
                            <option>Select</option>
                            {
                                users.map((user, index) => {
                                    return (
                                        <option key={index} value={user.id}>{user.gstNumber}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="form-group mb-3">
                        <label className="mb-2" htmlFor="transportType">Mode of Transport</label>
                        <select
                            className="form-select"
                            id="transportType"
                            name="transportType"
                            onChange={handleState}
                            defaultValue="ROAD"
                        >
                            <option value="ROAD">Road</option>
                            <option value="AIR">Air</option>
                            <option value="RAIL">Rail</option>
                        </select>
                    </div>
                    {
                        isLoginTypeConsignor && isModeOfTransportRoad &&
                        <div className="form-group mb-3">
                            <label className="mb-2" htmlFor="transporterGST">Transporter GSTIN</label>
                            <input
                                className="form-control"
                                id="transporterGST"
                                name="transporterGST"
                                placeholder="Transporter GSTIN"
                                onChange={handleState}
                            />
                        </div>
                    }
                    <div className="form-group mb-3">
                        <label className="mb-2" htmlFor="transactionType">Type of Transaction</label>
                        <select
                            className="form-select"
                            id="transactionType"
                            name="transactionType"
                            onChange={handleState}
                        >
                            <option>Select</option>
                            <option value="SALES">Sales</option>
                            <option value="SALES_RETURN">Sales Return</option>
                            <option value="EXPORT">Export</option>
                            <option value="IMPORT">Import</option>
                        </select>
                    </div>
                    {
                        isModeOfTransportRoad &&
                        <div className="form-group mb-3">
                            <label className="mb-2" htmlFor="vehicleNo">Vehicle Number</label>
                            <input
                                className="form-control"
                                id="vehicleNo"
                                name="vehicleNo"
                                placeholder="Vehicle No"
                                onChange={handleState}
                            />
                        </div>
                    }
                    <div className="form-group mb-3">
                        <label className="mb-2">Select Excel File</label>
                        <input
                            className="form-control"
                            type="file"
                            id="file"
                            name="file"
                            onChange={handleFileChange}
                        />
                    </div>
                    <button
                        className="btn btn-primary mb-5"
                        onClick={handleGenerateEWB}
                        disabled={isGenerateButtonDisabled}>
                        Generate
                    </button>
                </div>
                <div className="col-lg-7 m-5">
                    <div className="row">
                        <h4>Requests</h4>
                        <MainTable
                            handleDeleteEachReqFromDB={handleDeleteEachReqFromDB}
                            requestsDB={requestsDB}
                            handleDeleteAllReqFromDB={handleDeleteAllReqFromDB}
                            handleShowReqInDetail={handleShowReqInDetail}
                        />
                    </div>
                    {
                        requestInDetail !== undefined &&
                        <SubTable
                            requestInDetail={requestInDetail}
                            handleDownloadExcel={handleDownloadExcel}
                        />
                    }
                </div>
            </div>
        </div>
    )
}
export default Generate