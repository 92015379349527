import { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGooglePlusG } from "@fortawesome/free-brands-svg-icons"
import { CredentialsContext } from "../../API/CredentialsContext"
import { getUserCredentials } from "../../API/GetUserCreds"
import { Actions } from "../../Reducer/reducer"
import { useGoogleLogin } from "@react-oauth/google"
import { jwtDecode } from "jwt-decode"

const Login = () => {

    const [loginState, setLoginState] = useState({
        userName: "",
        password: "",
        errorMessage: '',
    })
    const { dispatch } = useContext(CredentialsContext)
    const navigate = useNavigate()
    const isButtonDisabled =
        loginState.userName === "" ||
        loginState.password === ""

    const handleState = (event) => {
        const { name, value } = event.target
        setLoginState(state => ({
            ...state,
            [name]: value
        }))
    }

    const getAuthTokenForCode = async (code) => {
        const redirect_uri = window.location.protocol + "//" + window.location.host
        const response = await fetch("https://app.e-waybill.in/auth/google-login", {
            method: "POST",
            body: JSON.stringify({ code, redirect_uri, redirectUri: redirect_uri })
        })
        if (response.ok) {
            const b = await response.json()
            const decode = jwtDecode(b["token"])
            console.log('from Token', b["token"], "got ", decode)
            return decode["sub"]
        }
        return null
    }

    function handleGoogleLoginFailure() {
        setLoginState(prev => ({ ...prev, errorMessage: 'Login Failed from Google' }))
    }

    const handleGoogleLogin = useGoogleLogin({
        scope: "email profile",
        onSuccess: codeResponse => {
            getAuthTokenForCode(codeResponse.code).then(authToken => {
                if (authToken) {
                    localStorage.setItem("authToken", authToken)
                    getUserCredentials(authToken)
                        .then(creds => {
                            dispatch({ type: Actions.UpdateCredentials, payload: creds })
                            dispatch({ type: Actions.HideLoading })
                            navigate('/e-waybill/consignor')
                        }, () => {
                            //fetch of creds failed
                        })
                } else {
                    handleGoogleLoginFailure()
                }
            }, () => {
                handleGoogleLoginFailure()
            })
        },
        flow: 'auth-code',
    })

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleLoginWithCredentials(event)
        }
    }

    const handleLoginWithCredentials = async (event) => {
        event.preventDefault()
        try {
            dispatch({ type: Actions.ShowLoading })

            const payload = {
                userName: loginState.userName,
                password: loginState.password
            }

            const response = await fetch('https://app.e-waybill.in/auth/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            })

            const data = await response.json()

            if (data.authToken) {
                localStorage.setItem("authToken", data.authToken)
                await handlePermissionCheck(data.authToken)
                getUserCredentials(data.authToken)
                    .then(creds => {
                        dispatch({ type: Actions.UpdateCredentials, payload: creds })
                        dispatch({ type: Actions.HideLoading })
                    })
            }
            if (data.title === "Invalid Login Credentials") {
                setLoginState((prev) => ({ ...prev, errorMessage: "Invalid Login Credentials" }))
            }
        }
        catch (error) { console.error('Error:', error) }
        finally { dispatch({ type: Actions.HideLoading }) }
    }

    const handlePermissionCheck = async (authToken) => {
        try {
            if (!authToken) return
            const response = await fetch(`https://app.e-waybill.in/app/user/me`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Auth-Token': authToken,
                },
            })
            const data = await response.json()
            const permissions = data.permissions
            const permittedPaths = {
                "GENERATE_PARTA": '/e-waybill/consignor',
                "GENERATE_DUPLICATE": '/duplicate',
                "GENERATE_RTO": '/rto',
                "UPDATE_PARTB": '/part-b/update',
                "EXTEND_EWAYBILL": '/expiry/expiring',
                "AUTO_EXTEND_EWAYBILL": '/auto-extend/reports',
                "VIEW_EWB_OPS": '/e-waybill/consignor'
            }
            const path = permissions.map(permission => permittedPaths[permission])[0]

            if (path) {
                navigate(path)
            } else {
                setLoginState((prev) => ({ ...prev, errorMessage: 'You Do Not Have Permission To Log In!', }))
                localStorage.removeItem('authToken')
                localStorage.removeItem('isAdmin')
                dispatch({ type: Actions.ClearCredentials })
                navigate('/')
            }
        }
        catch (e) { console.error('Error in fetching response from me api:', e) }
    }

    return (
        <form className="mb-4 pb-3" onSubmit={handleLoginWithCredentials}>
            <div className="d-flex justify-content-center mt-4">
                <div className="col-6 mt-5">
                    <h4 className="text-start row pb-4">Login</h4>
                    <div className="form-group row justify-content-start pb-3">
                        <label className="text-start p-0 pb-2">Username</label>
                        <input
                            type="text"
                            className="form-control"
                            name="userName"
                            value={loginState.userName}
                            onChange={handleState}
                            placeholder="Enter your Username"
                        />
                    </div>
                    <div className="form-group row justify-content-start pb-3">
                        <label className="text-start p-0 pb-2">Password</label>
                        <input
                            type="password"
                            className="form-control"
                            name="password"
                            value={loginState.password}
                            onChange={handleState}
                            placeholder="Enter your Password"
                        />
                    </div>
                    {
                        loginState.errorMessage !== "" &&
                        <div className="row justify-content-start alert alert-danger">
                            <div>{loginState.errorMessage}</div>
                        </div>
                    }
                    <div className="row justify-content-start">
                        <div className="form-group text-start p-0">
                            <button
                                className="btn btn-primary me-1"
                                type="submit"
                                disabled={isButtonDisabled}
                                onKeyDown={handleKeyDown}
                            >
                                Login
                            </button>
                            <button
                                className="btn btn-danger"
                                onClick={() => handleGoogleLogin()}
                                type="button"
                            >
                                <FontAwesomeIcon icon={faGooglePlusG} /> Login
                            </button>
                        </div>
                    </div>
                    <div className="row border justify-content-start my-4 alert alert-primary" role="alert">
                        <div>
                            This is a new version of the app.
                            Incase of any issues inform customer care (The email and contact details are given below) or temporarily use the old app at
                            <a href="https://app-v1.e-waybill.in" className="ms-1">https://app-v1.e-waybill.in</a>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}
export default Login
