import { useContext, useEffect, useState, useCallback } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons"
import { v4 as uuidv4 } from "uuid"
import ExtendModal from "../../../Modals/extendModal"
import { CredentialsContext } from "../../../API/CredentialsContext"
import { Actions } from "../../../Reducer/reducer"
import { ThreePulseCircles } from "../../../Animation/Animation"
import { utils, writeFileXLSX } from 'xlsx'
import ExpringEWBTable from "./Table"

const ExpiringEWB = () => {
    const [expiringEWBState, setExpiringEWBState] = useState({
        credential: {},
        keyword: ""
    })
    const [requests, setRequests] = useState([])
    const [modalRequests, setModalRequests] = useState([])
    const [ewbsToExtend, setEwbsToExtend] = useState([])
    const [modalState, setModalState] = useState({
        transhipmentLocation: "",
        transhipmentState: "",
        vehicleNo: ""
    })
    const { state, dispatch } = useContext(CredentialsContext)
    const users = state?.credentials || []

    const isPulseLoadingVisibleForSearchButton =
        requests[0]?.status === 400 ||
        state.loading

    const isPulseLoadingVisibleForExtendButton =
        state.loading ||
        (
            modalRequests?.length !== 0 &&
            modalRequests[0]?.response.status !== "COMPLETED"
        ) ||
        modalRequests[0]?.status === 400

    const isSearchButtonDisabled =
        Object.keys(expiringEWBState.credential)?.length === 0 ||
        isPulseLoadingVisibleForSearchButton

    const isExtendButtonDisabled =
        modalState.transhipmentLocation === "" ||
        modalState.transhipmentState === "" ||
        modalState.vehicleNo === "" ||
        isPulseLoadingVisibleForExtendButton

    const handleState = (event) => {
        const { name, value } = event.target
        if (name === 'credential') {
            setExpiringEWBState(prev => ({
                ...prev,
                [name]: users.find(u => u.id === +value)
            }))
        } else {
            setExpiringEWBState(prev => ({
                ...prev,
                [name]: value
            }))
        }
    }

    const handleSearchEWB = async () => {
        try {
            dispatch({ type: Actions.ShowLoading })
            const payload = {
                assigned: false,
                inward: false,
                outward: false,
                expiring: true,
                detailed: true,
                dateFrom: new Date().toISOString().slice(0, 10),
                dateTo: new Date().toISOString().slice(0, 10),
                credentials: {
                    userName: expiringEWBState.credential.userName,
                    password: expiringEWBState.credential.password,
                }
            }
            const requestId = uuidv4().replaceAll("-", "").substring(0, 11)
            const response = await fetch('https://prod-1.e-waybill.in/api/e-waybill/fetch', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...payload, requestId
                })
            })
            const data = await response.json()
            setRequests([{ requestId, status: data.status, response: data, request: payload, checkCount: 0 }])
        } catch (error) {
            console.error('Error in searching ewb:', error)
        }
    }

    const handleEWBCheckbox = (item) => {
        if (ewbsToExtend.indexOf(item.ewaybill) === -1)
            setEwbsToExtend([...ewbsToExtend, item.ewaybill])
        else {
            setEwbsToExtend(ewbsToExtend.filter(x => x !== item.ewaybill))
        }
    }

    const handleModalState = (event) => {
        const { name, value } = event.target
        setModalState(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleExtendEWB = async () => {
        try {
            dispatch({ type: Actions.ShowLoading })
            const payload = {
                extendFor: 1,
                state: modalState.transhipmentState,
                transhipmentLocation: modalState.transhipmentLocation,
                vehicleNo: modalState.vehicleNo,
                credentials: {
                    userName: expiringEWBState.credential.userName,
                    password: expiringEWBState.credential.password,
                }
            }
            const ewaybillNos = ewbsToExtend
            const requestId = uuidv4().replaceAll("-", "").substring(0, 11)
            const response = await fetch('https://prod-1.e-waybill.in/api/e-waybill/extend', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...payload, requestId, ewaybillNos
                })
            })
            const data = await response.json()
            setModalRequests([{ requestId, status: data.status, response: data, request: payload, checkCount: 0 }])
        }
        catch (error) { console.error('Error in searching ewb:', error) }
        finally { dispatch({ type: Actions.HideLoading }) }
    }

    const clearModalRequests = () => {
        setModalRequests([])
    }

    const isExcelDownloadDisabled = requests?.length === 0 || isPulseLoadingVisibleForSearchButton
    const handleDownloadExcel = () => {
        const newArray = requests[0]?.response.ewaybillDetails.map(({
            ewaybill,
            consignorGst,
            consignorAddr,
            consignorPin,
            consigneeGst,
            consigneeAddr,
            consigneePin,
            validTill }) => ({
                ewaybill,
                consignorGst,
                consignorAddr,
                consignorPin,
                consigneeGst,
                consigneeAddr,
                consigneePin,
                validTill
            }))
        const date = new Date().toLocaleString()
        const ws = utils.json_to_sheet(newArray)
        const wb = utils.book_new()
        utils.book_append_sheet(wb, ws, "Data")
        writeFileXLSX(wb, `ExpiringEWB${date}.xlsx`)
    }

    useEffect(() => {
        const handleStatusCheckForSearchAPI = async (r) => {
            if (r.status === 'COMPLETED' || r.checkCount > 10000) return r
            try {
                dispatch({ type: Actions.ShowLoading })
                const url = `https://prod-1.e-waybill.in/api/e-waybill/fetch-status?request_id=${r.requestId}&filter=expiry`
                const response = await fetch(url)
                const data = await response.json()
                if (data.status === 'COMPLETED') {
                    return { ...r, status: 'COMPLETED', response: data, checkCount: r.checkCount + 1 }
                } else {
                    return { ...r, status: data.status, checkCount: r.checkCount + 1 }
                }
            }
            catch (error) { console.error('Error in checking status of expiring EWB:', error) }
            finally { dispatch({ type: Actions.HideLoading }) }
            return r
        }

        const interval = setTimeout(() => {
            const checkForStatus = async () => {
                const results = await Promise.all(requests
                    .map(async (r) => {
                        return await handleStatusCheckForSearchAPI(r)
                    }))
                setRequests(results)
            }
            checkForStatus().then(() => {
                console.info("check completed....")
            })
        }, 5000)
        return () => clearInterval(interval)
    }, [requests, dispatch])

    useEffect(() => {
        const handleStatusCheckForExtendAPI = async (r) => {
            if (r.status === 'COMPLETED' || r.checkCount > 10000) return r
            try {
                dispatch({ type: Actions.ShowLoading })
                const url = `https://prod-1.e-waybill.in/api/e-waybill/extend-status?request_id=${r.requestId}`
                const response = await fetch(url)
                const data = await response.json()
                if (data.status === 'COMPLETED') {
                    return { ...r, status: 'COMPLETED', response: data, checkCount: r.checkCount + 1 }
                } else {
                    return { ...r, status: data.status, checkCount: r.checkCount + 1 }
                }
            }
            catch (error) { console.error('Error in checking status of expiring EWB:', error) }
            finally { dispatch({ type: Actions.HideLoading }) }
            return r
        }

        const interval = setTimeout(() => {
            const checkForStatusOfExtendAPI = async () => {
                const results = await Promise.all(modalRequests
                    .map(async (r) => {
                        return await handleStatusCheckForExtendAPI(r)
                    }))
                setModalRequests(results)
            }
            checkForStatusOfExtendAPI().then(() => {
                console.info("check completed....")
            })
        }, 5000)
        return () => clearInterval(interval)
    }, [modalRequests, dispatch])

    return (
        <div className="py-2">
            <div className="row">
                <div className="col-lg-3 m-3">
                    <h4>EWaybill Expiry</h4>
                    <div className="form-group mb-3">
                        <label htmlFor='credential' className="mb-2">Credential</label>
                        <select className="form-select"
                            id="credential"
                            name="credential"
                            onChange={handleState}
                        >
                            <option>Select</option>
                            {users.map((user, index) => {
                                return (
                                    <option key={index} value={user.id}>{user.gstNumber}</option>
                                )
                            })}
                        </select>
                    </div>
                    <button
                        className="btn btn-primary"
                        onClick={handleSearchEWB}
                        disabled={isSearchButtonDisabled}
                    >
                        {
                            isPulseLoadingVisibleForSearchButton ? <ThreePulseCircles /> :
                                <>
                                    <FontAwesomeIcon icon={faMagnifyingGlass} /> Search
                                </>
                        }
                    </button>
                </div>
                <div className="col-lg-7 m-5">
                    <div className="d-flex mb-3 justify-content-start">
                        <h4>EWaybills Expiring</h4>
                        <div className="ms-4">
                            <input
                                className="form-control"
                                placeholder="Search EWB Number"
                                name="keyword"
                                id="keyword"
                                onChange={handleState}
                            />
                        </div>
                        <button
                            className="btn btn-primary ms-4"
                            onClick={handleDownloadExcel}
                            disabled={isExcelDownloadDisabled}>
                            Download Excel
                        </button>
                    </div>
                    <ExpringEWBTable
                        ewbsToExtend={ewbsToExtend}
                        requests={requests}
                        expiringEWBState={expiringEWBState}
                        handleEWBCheckbox={handleEWBCheckbox}
                    />
                </div>
            </div>
            <ExtendModal
                handleModalState={handleModalState}
                modalState={modalState}
                modalRequests={modalRequests}
                handleExtendEWB={handleExtendEWB}
                clearModalRequests={clearModalRequests}
                isExtendButtonDisabled={isExtendButtonDisabled}
                isPulseLoadingVisibleForExtendButton={isPulseLoadingVisibleForExtendButton}
            />
        </div>
    )
}
export default ExpiringEWB