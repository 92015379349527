export const DBConfiguration = {
    name: "MyDatabaseV2",
    version: 13,
    objectStoresMeta: [
        {
            store: "RequestsForPartAV4",
            storeConfig: { keyPath: "requestId" },
            storeSchema: [
                { name: "requestId", keyPath: "requestId", options: { unique: true } },
                { name: "requestTime", keyPath: "requestTime", options: { unique: false } },
                { name: "requestTimeStr", keyPath: "requestTimeStr", options: { unique: false } },
                { name: "status", keyPath: "status", options: { unique: false } },
                { name: "response", keyPath: "response", options: { unique: false } },
                { name: "request", keyPath: "request", options: { unique: false } },
                { name: "checkCount", keyPath: "checkCount", options: { unique: false } },
            ]
        },
        {
            store: "RequestsForPartB",
            storeConfig: { keyPath: "requestId" },
            storeSchema: [
                { name: "requestId", keyPath: "requestId", options: { unique: true } },
                { name: "requestTime", keyPath: "requestTime", options: { unique: false } },
                { name: "requestTimeStr", keyPath: "requestTimeStr", options: { unique: false } },
                { name: "status", keyPath: "status", options: { unique: false } },
                { name: "response", keyPath: "response", options: { unique: false } },
                { name: "request", keyPath: "request", options: { unique: false } },
                { name: "checkCount", keyPath: "checkCount", options: { unique: false } },
            ]
        },
        {
            store: "RequestsForPrintEWB",
            storeConfig: { keyPath: "requestId" },
            storeSchema: [
                { name: "requestId", keyPath: "requestId", options: { unique: true } },
                { name: "requestTime", keyPath: "requestTime", options: { unique: false } },
                { name: "requestTimeStr", keyPath: "requestTimeStr", options: { unique: false } },
                { name: "status", keyPath: "status", options: { unique: false } },
                { name: "response", keyPath: "response", options: { unique: false } },
                { name: "request", keyPath: "request", options: { unique: false } },
                { name: "checkCount", keyPath: "checkCount", options: { unique: false } },
            ]
        },
        {
            store: "RequestsForMVUTable",
            storeConfig: { keyPath: "requestId" },
            storeSchema: [
                { name: "requestId", keyPath: "requestId", options: { unique: true } },
                { name: "requestTime", keyPath: "requestTime", options: { unique: false } },
                { name: "requestTimeStr", keyPath: "requestTimeStr", options: { unique: false } },
                { name: "status", keyPath: "status", options: { unique: false } },
                { name: "response", keyPath: "response", options: { unique: false } },
                { name: "request", keyPath: "request", options: { unique: false } },
                { name: "total", keyPath: "total", options: { unique: false } },
                { name: "checkCount", keyPath: "checkCount", options: { unique: false } },
            ]
        }
    ]
}