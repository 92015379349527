import { useState, useContext, useEffect } from "react"
import { CredentialsContext } from "../../../API/CredentialsContext"
import { Actions } from "../../../Reducer/reducer"
import Table from "./Table"

const User = () => {

    const [userState, setUserState] = useState({
        userName: "",
        password: "",
        permissions: "VIEW_EWB_OPS",
        users: [],
        selectedUser: {},
        isNewUser: true,
        isChecked: false
    })
    const { dispatch } = useContext(CredentialsContext)
    const authToken = localStorage.getItem('authToken')
    const isSaveButtonDisabled =
        userState.userName === "" ||
        userState.password === ""

    const handleState = (event) => {
        const { name, value, type, checked } = event.target
        if (type === "checkbox") {
            setUserState(prev => ({
                ...prev,
                isChecked: checked
            }))
        } else {
            setUserState(prev => ({
                ...prev,
                [name]: value
            }))
        }
    }

    const handleFetchUsers = async () => {
        try {
            dispatch({ type: Actions.ShowLoading })
            const response = await fetch('https://app-v1.e-waybill.in/app/user',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Auth-Token': authToken,
                    },
                }
            )
            if (response.ok) {
                const data = await response.json()
                setUserState((prev) => ({
                    ...prev,
                    users: data
                }))
            } else {
                console.error("Error in getting response")
            }
        }
        catch (e) { console.error("Unable to fetch users", e) }
        finally {
            dispatch({ type: Actions.HideLoading })
        }
    }

    const handleEnableOrDisableUser = async (id) => {
        try {
            await fetch(`https://app-v1.e-waybill.in/app/user?id=${id}`, {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json',
                    'X-Auth-Token': authToken,
                }
            })
            handleFetchUsers()
        }
        catch (e) { console.error("Unable to change user condition") }
        finally { dispatch({ type: Actions.HideLoading }) }
    }

    const handleEditUser = (selectedUser) => {
        setUserState((prev) => ({
            ...prev,
            userName: selectedUser.userName,
            password: selectedUser.password,
            selectedUser: selectedUser,
            permissions: selectedUser.permissions[0],
            isNewUser: false
        }))
    }

    const handleCreateUser = async (e) => {
        e.preventDefault()
        try {
            dispatch({ type: Actions.ShowLoading })

            let payload;

            if (userState.isNewUser) {
                payload = {
                    password: userState.password,
                    userName: userState.userName
                }
                if (userState.isChecked) {
                    payload = { ...payload, admin: true }
                } else {
                    payload = { ...payload, permissions: [userState.permissions] }
                }
            }
            else {
                payload = {
                    ...userState.selectedUser,
                    password: userState.password,
                    userName: userState.userName,
                    permissions: [userState.permissions]
                }
                if (userState.isChecked) {
                    payload = { ...payload, isAdmin: true }
                }
            }

            if (isSaveButtonDisabled) {
                await fetch(`https://app-v1.e-waybill.in/app/user`, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Auth-Token': authToken,
                    },
                    body: JSON.stringify(payload)
                })
            }

            setUserState((prev) => ({
                ...prev,
                userName: "",
                password: "",
                isNewUser: true,
                selectedUser: {},
                isChecked: false
            }))

            handleFetchUsers()
        }
        catch (e) {
            console.error("Unable to create new user", e)
        }
        finally { dispatch({ type: Actions.HideLoading }) }
    }

    useEffect(() => {
        const handleFetchUsers = async () => {
            try {
                dispatch({ type: Actions.ShowLoading })
                const response = await fetch('https://app-v1.e-waybill.in/app/user',
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'X-Auth-Token': authToken,
                        },
                    }
                )
                if (response.ok) {
                    const data = await response.json()
                    setUserState((prev) => ({
                        ...prev,
                        users: data
                    }))
                } else {
                    console.error("Error in getting response")
                }
            }
            catch (e) { console.error("Unable to fetch users", e) }
            finally {
                dispatch({ type: Actions.HideLoading })
            }
        }
        handleFetchUsers()
    }, [authToken, dispatch])

    return (
        <div className="row justify-content-between">
            <form
                className="col-lg-3 m-3"
                onSubmit={handleCreateUser}
            >
                <h4 className="mb-3">New User</h4>
                <div className="form-group mb-2">
                    <label className="mb-2" htmlFor="userName">User Name</label>
                    <input
                        className="form-control"
                        type="text"
                        id="userName"
                        name="userName"
                        placeholder="Enter your UserName"
                        value={userState.userName}
                        onChange={handleState}
                    />
                </div>
                <div className="form-group mb-3">
                    <label className="mb-2" htmlFor="userName">Password</label>
                    <input
                        type="text"
                        className="form-control"
                        id="password"
                        name="password"
                        placeholder="Enter your Password"
                        value={userState.password}
                        onChange={handleState}
                    />
                </div>
                <div className="form-check mb-2">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        name="isAdmin"
                        id="isAdmin"
                        checked={userState.isChecked}
                        onChange={handleState}
                    />
                    <label htmlFor="isAdmin">Is Admin?</label>
                </div>
                {
                    !userState.isChecked &&
                    <div className="form-group mb-2">
                        <label className="mb-2" htmlFor="userName">Permissions</label>
                        <select
                            className="form-select"
                            id="permissions"
                            name="permissions"
                            value={userState.permissions}
                            onChange={handleState}
                        >
                            <option value="VIEW_EWB_OPS">EWB Ops</option>
                            <option value="VIEW_API">View APIs</option>
                        </select>
                    </div>
                }
                <button
                    className="btn btn-primary"
                    disabled={isSaveButtonDisabled}
                    type="submit"
                >
                    Save
                </button>
            </form>
            <Table
                userState={userState}
                handleEditUser={handleEditUser}
                handleEnableOrDisableUser={handleEnableOrDisableUser}
            />
        </div>
    )
}
export default User