export const PrintTableHeader = [
    "SL.NO",
    "Ewaybill",
    "Consignor",
    "Consignee",
    "Invoice",
    "Transporter",
    "Generated",
    "Expiry",
    "Valid / Error ?"
]