import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import IndianStates from '../Data/IndianStates.json'
import classNames from "classnames"
import { ThreePulseCircles } from "../Animation/Animation"

const ExtendModal = ({ handleModalState, modalState, modalRequests, handleExtendEWB, clearModalRequests, isExtendButtonDisabled, isPulseLoadingVisibleForExtendButton }) => {
    return (
        <div className="modal fade" id="extendEWBModal" tabIndex="-1" aria-labelledby="extendEWBModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title">Enter Details</h3>
                    </div>
                    <div className="modal-body">
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <th className="text-uppercase">
                                        <label htmlFor="transhipmentLocation">
                                            Transhipment Location
                                        </label>
                                    </th>
                                    <td>
                                        <input
                                            className="form-control"
                                            id="transhipmentLocation"
                                            value={modalState.transhipmentLocation}
                                            name="transhipmentLocation"
                                            onChange={handleModalState}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th className="text-uppercase">
                                        <label htmlFor="transhipmentState">
                                            Transhipment State
                                        </label>
                                    </th>
                                    <td>
                                        <select className="form-select"
                                            value={modalState.transhipmentState}
                                            name="transhipmentState"
                                            id="transhipmentState"
                                            onChange={handleModalState}>
                                            <option>Select</option>
                                            {
                                                IndianStates.map((state, index) => {
                                                    return (
                                                        <option key={index} value={state.value}>{state.label}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="text-uppercase">
                                        <label htmlFor="vehicleNo">Vehicle No</label>
                                    </th>
                                    <td>
                                        <input
                                            className="form-control"
                                            id="vehicleNo"
                                            value={modalState.vehicleNo}
                                            name="vehicleNo"
                                            onChange={handleModalState}
                                            maxLength="10"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <hr className="mt-5" />
                        <table className="table">
                            <thead>
                                <tr className="text-uppercase">
                                    <th>EWB</th>
                                    <th>Status</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    modalRequests[0]?.response.ewaybillNos.map((ewbNo, index) => {
                                        const success = modalRequests[0].response.validity
                                        const errors = modalRequests[0].response.errors
                                        return (
                                            <tr key={index} >
                                                <td>{ewbNo}</td>
                                                <td className={classNames({
                                                    "text-success": success[ewbNo],
                                                    "text-danger": errors[ewbNo]
                                                })}>
                                                    {success[ewbNo] || errors[ewbNo]}
                                                </td>
                                                <td>{modalRequests[0].response.status !== "COMPLETED" && <ThreePulseCircles />}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-primary"
                            disabled={isExtendButtonDisabled}
                            onClick={handleExtendEWB}>
                            {
                                isPulseLoadingVisibleForExtendButton ?
                                    <ThreePulseCircles /> :
                                    <> <FontAwesomeIcon icon={faCheck} /> Extend</>
                            }
                        </button>
                        <button
                            type="button"
                            className="btn btn-warning"
                            data-bs-dismiss="modal"
                            onClick={clearModalRequests}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ExtendModal