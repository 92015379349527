import React, { useEffect, useState, useContext } from "react" // eslint-disable-line import/first
import { CredentialsContext } from "../../../API/CredentialsContext"
import { v4 as uuidv4 } from "uuid"
import Table from "./Table"
import { Actions } from "../../../Reducer/reducer"

const MultipleEWB = () => {
    const [printState, setPrintState] = useState({
        credential: {},
        ewaybillNos: ""
    })
    const [requests, setRequests] = useState([])
    const { state, dispatch } = useContext(CredentialsContext)
    const users = state?.credentials || []
    const isSearchButtonDisabled =
        Object.keys(printState.credential).length === 0 ||
        printState.ewaybillNos === "" ||
        state.loading

    const handleState = (event) => {
        const { name, value } = event.target
        if (name === 'credential') {
            setPrintState(prev => ({
                ...prev,
                [name]: users.find(u => u.id === +value)
            }))
        } else {
            setPrintState(prev => ({
                ...prev,
                [name]: value
            }))
        }
    }

    const handleSearchEWB = async () => {
        try {
            dispatch({ type: Actions.ShowLoading })
            const payload = {
                credentials: {
                    userName: printState.credential.userName,
                    password: printState.credential.password,
                    gstNumber: printState.credential.gstNumber
                },
                ewaybillNos: printState.ewaybillNos.split('\n')
            }
            const requestId = uuidv4().replaceAll("-", "").substring(0, 11)
            const url = 'https://api.e-waybill.in/api/e-waybill/bulk-get?for=print'
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...payload, requestId: requestId })
            })
            if (response.ok) {
                const data = await response.json()
                setRequests([{ requestId, status: data.status, response: data, request: payload, checkCount: 0 }])
            }
        }
        catch (error) { console.error('Error in getting response from print multiple EWB api:', error) }
    }

    useEffect(() => {
        const handleStatusCheck = async (r) => {
            if (r.status === 'COMPLETED' || r.checkCount > 10000) return r
            try {
                dispatch({ type: Actions.ShowLoading })
                const response = await fetch(`https://api.e-waybill.in/api/e-waybill/bulk-get-status?request_id=${r.requestId}&for=print`)
                const data = await response.json()
                if (data.status === 'COMPLETED') {
                    return { ...r, status: 'COMPLETED', response: data, checkCount: r.checkCount + 1 }
                } else {
                    return { ...r, status: data.status, checkCount: r.checkCount + 1 }
                }
            }
            catch (error) { console.error('Error in checking status of print multiple EWB api:', error) }
            finally { dispatch({ type: Actions.HideLoading }) }
            return r
        }

        const interval = setTimeout(() => {
            const checkForStatus = async () => {
                const results = await Promise.all(requests
                    .map(async (r) => {
                        return await handleStatusCheck(r)
                    }))
                setRequests(results)
            }
            checkForStatus().then(() => {
                console.info("check completed....")
            })
        }, 5000)
        return () => clearInterval(interval)
    }, [requests, dispatch])

    return (
        <div className="py-2">
            <div className="row">
                <div className="col-lg-3 mt-5">
                    <h4>Print Multiple EWB</h4>
                    <div className="form-group mb-3">
                        <label htmlFor='credential' className="mb-2">Credential</label>
                        <select
                            className="form-select"
                            id="credential"
                            name="credential"
                            onChange={handleState}
                        >
                            <option>Select</option>
                            {users.map((user, index) => {
                                return (
                                    <option key={index} value={user.id}>{user.gstNumber}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="form-group mb-3">
                        <textarea
                            className="form-control"
                            style={{ height: "200px" }}
                            type="text"
                            name="ewaybillNos"
                            id="ewaybillNos"
                            placeholder="EWaybill #, one per line"
                            onChange={handleState}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <button
                            className="btn btn-primary"
                            onClick={handleSearchEWB}
                            disabled={isSearchButtonDisabled}
                        >
                            Search
                        </button>
                    </div>
                </div>
                <div className="col-lg-9 my-5">
                    <div className="overflow-x-auto mb-5">
                        <Table
                            printState={printState}
                            requests={requests}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default MultipleEWB