const PartBMultiVehicleUpdateSubTable = ({request}) => {
    return (
        <table className="table table-borderless table-sm">
            <thead>
                <tr className="text-uppercase">
                    <th>Vehicle</th>
                    <th>Qty</th>
                </tr>
            </thead>
            <tbody>
                {
                    request.response.vehicles.map((vehicle, index) => {
                        return (
                            <tr key={index}>
                                <td>{vehicle.vehicle}</td>
                                <td>{vehicle.qty} {request.request.uom}</td>
                            </tr>
                        )
                    })
                }
            </tbody>
            <tfoot>
                <tr>
                    <td>Total</td>
                    <td>{request.total} {request.request.uom}</td>
                </tr>
            </tfoot>
        </table>
    )
}
export default PartBMultiVehicleUpdateSubTable;