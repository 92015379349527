import React, { useCallback, useContext, useState } from "react" // eslint-disable-line import/first
import { utils, writeFileXLSX } from 'xlsx'
import { v4 as uuidv4 } from "uuid"
import { CredentialsContext } from "../../../API/CredentialsContext"
import { Actions } from "../../../Reducer/reducer"
import Table from "./Table"

const Duplicate = () => {
    const [partADuplicateState, setPartADuplicateState] = useState({
        credential: {},
        ewbs: ""
    })
    const [requests, setRequests] = useState([])
    const { state, dispatch } = useContext(CredentialsContext)
    const users = state?.credentials || []
    const isGenerateButtonDisabled =
        partADuplicateState.ewbs === "" ||
        Object.keys(partADuplicateState.credential).length === 0 ||
        state.loading ||
        requests[0]?.status === 400

    const handleState = (event) => {
        const { name, value } = event.target
        if (name === 'credential') {
            setPartADuplicateState(prev => (
                {
                    ...prev,
                    [name]: users.find(u => u.id === +value)
                })
            )
        } else {
            setPartADuplicateState(prev => (
                {
                    ...prev,
                    [name]: value
                })
            )
        }
    }

    const handleGenerateDuplicateEWB = async () => {
        try {
            dispatch({ type: Actions.ShowLoading })
            const payload = {
                requestId: uuidv4().replaceAll("-", "").substring(0, 10),
                credential: {
                    userName: partADuplicateState.credential.userName,
                    password: partADuplicateState.credential.password,
                    gstNumber: partADuplicateState.credential.gstNumber
                },
                ewaybillNos: partADuplicateState.ewbs.split("\n"),
                suffix: ""
            }
            const response = await fetch('https://prod-1.e-waybill.in/api/e-waybill/regen',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                })
            const responseData = await response.text()
            const rows = responseData.split('\n')
            const data = rows.map(row => row.trim().split(','))
            setRequests([{ status: data.status, response: data, request: payload }])
        }
        catch (error) { console.error('Error in generating duplicate ewb:', error) }
        finally { dispatch({ type: Actions.HideLoading }) }
    }

    const handleDownloadExcel = () => {
        const convertedArray =
            requests[0]?.response.slice(1, -1).map(
                ([
                    oldEWB,
                    newEWB,
                    validity,
                    status
                ]) => ({
                    oldEWB,
                    newEWB,
                    validity,
                    status,
                }))
        const date = new Date().toLocaleString()
        const ws = utils.json_to_sheet(convertedArray)
        const wb = utils.book_new()
        utils.book_append_sheet(wb, ws, "Data")
        writeFileXLSX(wb, `Duplicate_${date}.xlsx`)
    }

    return (
        <div className="py-2">
            <div className="row">
                <div className="col-lg-3 m-3">
                    <h4>Generate Duplicate EWB</h4>
                    <div className="form-group mb-3">
                        <label htmlFor='credential' className="mb-2">Credential</label>
                        <select className="form-select" onChange={handleState} id="credential" name="credential">
                            <option>Select</option>
                            {
                                users.map((user, index) => {
                                    return (
                                        <option key={index} value={user.id}>{user.gstNumber}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="form-group mb-3">
                        <textarea
                            className="form-control"
                            style={{ height: "200px" }}
                            id="ewbs"
                            name="ewbs"
                            placeholder="OLD EWaybill #, one per line (max 10)"
                            onChange={handleState}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <button
                            className="btn btn-primary"
                            onClick={handleGenerateDuplicateEWB}
                            disabled={isGenerateButtonDisabled}
                        >
                            Generate Duplicate
                        </button>
                    </div>
                </div>
                <div className="col-lg-7 m-5">
                    <div className="overflow-x-auto mb-3">
                        <Table
                            requests={requests}
                            handleDownloadExcel={handleDownloadExcel}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Duplicate