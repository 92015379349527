export const initialState = {
    credentials: [],
    loading: false,
}

export const Actions = {
    UpdateCredentials: "UPDATE_CREDS",
    ClearCredentials: "CLEAR_CREDS",
    ShowLoading: 'SHOW_LOADING',
    HideLoading: 'HIDE_LOADING'
}

export const reducer = (state, action) => {
    switch (action.type) {
        case Actions.UpdateCredentials:
            return { ...state, credentials: [...action.payload] }
        case Actions.ClearCredentials:
            return { ...state, credentials: [] }
        case Actions.ShowLoading:
            return { ...state, loading: true }
        case Actions.HideLoading:
            return { ...state, loading: false }
        default:
            return state
    }
}