import classNames from "classnames"
import ReactPaginate from "react-paginate"
import { useState } from "react"

const Table = ({ rsState }) => {

    const data = rsState.reportsData
    const PER_PAGE = 30
    const [currentPage, setCurrentPage] = useState(0)
    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage)
    }
    const offset = currentPage * PER_PAGE
    const pageCount = Math.ceil(data.length / PER_PAGE)

    return (
        <>
            <table className="table table-borderless table-striped mt-4 mb-5">
                <thead>
                    <tr className="text-uppercase table-dark">
                        <th>SL.NO</th>
                        <th>EWB</th>
                        <th>Old Validity</th>
                        <th>New Validity</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.length === 0 ?
                            <tr>
                                <td colSpan={4} className="text-center text-danger">No Reports Found !</td>
                            </tr> :
                            !rsState.showError ?
                                data
                                    .filter(item => item.ewb.includes(rsState.keyword))
                                    .slice(offset, offset + PER_PAGE)
                                    .map((item, index) => {
                                        return (
                                            <tr key={index}
                                                className={classNames({
                                                    "table-warning": !item.newValidity,
                                                })}>
                                                <td>{offset + index + 1}</td>
                                                <td>{item.ewb}</td>
                                                <td>{item.oldValidity}</td>
                                                <td>{item.newValidity ? item.newValidity : item.errorMsg || "ERROR"}</td>
                                            </tr>
                                        )
                                    }) :
                                data
                                    .filter(item => item.ewb.includes(rsState.keyword) && item.newValidity === null)
                                    .slice(offset, offset + PER_PAGE)
                                    .map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{offset + index + 1}</td>
                                                <td>{item.ewb}</td>
                                                <td>{item.oldValidity}</td>
                                                <td className="text-danger">{item.errorMsg || "ERROR"}</td>
                                            </tr>
                                        )
                                    })
                    }
                </tbody>
            </table>
            {data.length > PER_PAGE && <div className="mb-5">
                <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination  justify-content-center"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active primary"}
                />
            </div>}
        </>
    )
}
export default Table