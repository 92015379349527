const ApiCallsForm = ({ apiState, handleState, handleCheckbox, handleUpdate, checkbox }) => {
    return (
        <div className="d-flex justify-content-center flex-wrap">
            <div className="form-group mb-3 me-3">
                <input type="date" className="form-control" name="date" id="date" value={apiState.date} onChange={handleState} />
            </div>
            <div className="form-group mb-3 me-3">
                <select className="form-select" name="requestType" id="requestType" onChange={handleState}>
                    <option value="GENERATE">GENERATE</option>
                    <option value="UPDATE">UPDATE</option>
                    <option value="EXTEND">EXTEND</option>
                    <option value="GET">GET</option>
                    <option value="CHANGE">CHANGE</option>
                    <option value="Index">Index</option>
                </select>
            </div>
            <div className="form-check d-flex align-items-center mb-3 me-3">
                <input
                    className="form-check-input me-1"
                    type="checkbox"
                    name="showFailedItems"
                    id="showFailedItems"
                    value="showFailedItems"
                    onChange={handleCheckbox}
                />
                <label htmlFor="showFailedItems">Show Only Failed Items</label>
            </div>
            <div className="form-group mb-3 me-3">
                <select className="form-select" name="sortingOrder" id="sortingOrder" onChange={handleState}>
                    <option value="desc">New Request First</option>
                    <option value="asc">Old Request First</option>
                </select>
            </div>
            <div className="form-check d-flex align-items-center mb-3 me-3">
                <input
                    className="form-check-input me-1"
                    type="checkbox"
                    name="autoUpdate"
                    id="autoUpdate"
                    value="autoUpdate"
                    defaultChecked={checkbox.includes('autoUpdate')}
                    onChange={handleCheckbox}
                />
                <label htmlFor="autoUpdate">Auto Update View</label>
            </div>
            <div className="form-group mb-3">
                <button className="btn btn-primary" onClick={handleUpdate}> Update</button>
            </div>
        </div>
    )
}
export default ApiCallsForm