import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPenToSquare, faCheck, faTimes } from "@fortawesome/free-solid-svg-icons"
import classNames from "classnames"

const Table = ({ creds, handleSelectCredential }) => {
    return (
        <div className="overflow-x-auto mb-3">
            <table className="table table-sm">
                <thead>
                    <tr className="text-uppercase">
                        <th>User Name</th>
                        <th>Password</th>
                        <th>GST Number</th>
                        <th>Fetch Data?</th>
                        <th>Auto Extend?</th>
                        <th>Valid?</th>
                        <th>OTP</th>
                        <th>&nbsp;</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {creds.map((user, index) => {
                        return (
                            <tr key={index}>
                                <td>{user.userName}</td>
                                <td>{user.password}</td>
                                <td>{user.gstNumber}</td>
                                <td>
                                    <FontAwesomeIcon
                                        icon={user.useForFetch ? faCheck : faTimes}
                                        className={classNames({
                                            'text-success': user.useForFetch,
                                            'text-danger': !user.useForFetch
                                        })}
                                    />
                                </td>
                                <td>
                                    <FontAwesomeIcon
                                        icon={user.autoExtend ? faCheck : faTimes}
                                        className={classNames({
                                            'text-success': user.autoExtend,
                                            'text-danger': !user.autoExtend
                                        })}
                                    />
                                </td>
                                <td>
                                    <FontAwesomeIcon
                                        icon={user.valid ? faCheck : faTimes}
                                        className={classNames({
                                            'text-success': user.valid,
                                            'text-danger': !user.valid
                                        })}
                                    />
                                </td>
                                <td>
                                    {
                                        user.otp &&
                                        <>
                                            <div>OTP</div>
                                            <div>Valid For</div>
                                        </>
                                    }
                                </td>
                                <td>
                                    {
                                        user.otp &&
                                        <>
                                            <div>{user.otp}</div>
                                            <div>{user.seconds} Seconds</div>
                                        </>
                                    }
                                </td>
                                <td
                                    className="text-success"
                                >
                                    <button
                                        type="button"
                                        className="btn btn-success"
                                        onClick={handleSelectCredential.bind(this, index)}
                                    >
                                        <FontAwesomeIcon icon={faPenToSquare} />
                                    </button>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}
export default Table