import { useCallback, useContext, useEffect, useState } from "react"
import { read, utils, writeFileXLSX } from 'xlsx'
import { v4 as uuidv4 } from "uuid"
import { CredentialsContext } from "../../../API/CredentialsContext"
import { Actions } from "../../../Reducer/reducer"
import Table from "./Table"

const ExtendEWB = () => {

    const [extendState, setExtendState] = useState({
        credential: {},
        fileData: []
    })
    const [requests, setRequests] = useState([])
    const { state, dispatch } = useContext(CredentialsContext)
    const users = state?.credentials || []
    const isExtendButtonDisabled =
        Object.keys(extendState.credential).length === 0 ||
        state.loading ||
        document.getElementById("file").value === ""
    const TotalCount = requests[0]?.request?.items?.length || 0
    const SuccessCount = requests[0]?.successCount || 0
    const ErrorCount = requests[0]?.errorCount || 0
    const PendingCount = (TotalCount - (SuccessCount + ErrorCount)) || 0

    const handleCredentialChange = (event) => {
        const selectedOption = event.target.value
        const selectedUser = users.find(user => user.gstNumber === selectedOption)
        setExtendState((prev) => ({ ...prev, credential: selectedUser }))
    }

    const handleFileChange = async (event) => {
        await handleExcelToObj(event.target.files[0])
    }

    const handleExcelToObj = async (file) => {
        if (file) {
            const ab = await file.arrayBuffer()
            const wb = read(ab)
            const ws = wb.Sheets[wb.SheetNames[0]]
            const dataFromFile = utils.sheet_to_json(ws)
            setExtendState((prev) => ({ ...prev, fileData: dataFromFile }))
        } else {
            console.error('File is null')
        }
    }

    const handleExtendEWB = async () => {
        try {
            dispatch({ type: Actions.ShowLoading })

            const payload = {
                alwaysGiveValidity: true,
                credentials: {
                    userName: extendState.credential.userName,
                    password: extendState.credential.password,
                    gstNumber: extendState.credential.gstNumber
                },
                items: extendState.fileData?.map((row) => {

                    let eachItem;

                    if (row.vehicle_number.toLowerCase() === "none") {
                        eachItem = {
                            ewb: row.ewbn?.toString(),
                            client: row.cl,
                            currentPlace: row.current_city?.toUpperCase(),
                            wbn: row.wbn.toString() || "",
                            currentPinCode: row.current_pincode?.toString() || "560078",
                            currentStatus: "IN_WAREHOUSE",
                            extendDistance: 101,
                            extending: true,
                            wareHousePlace3: "NONE",
                            state: row.current_state?.toUpperCase(),
                            status: "INIT"
                        }
                    } else {
                        eachItem = {
                            ...eachItem,
                            vehicleNo: row.vehicle_number || "",
                            currentStatus: "IN_TRANSIT"
                        }
                    }

                    return eachItem
                }) || []
            }
            const requestId = uuidv4().replaceAll("-", "").substring(0, 11)
            const url = 'https://prod-1.e-waybill.in/api/v2/extend/bulk'
            await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...payload, requestId }),
            })

            const data = {}
            payload.items = payload.items.map(x => ({ ...x, status: 'INIT' }))

            setRequests([{
                requestId,
                fullStatus: 'INIT',
                response: data,
                request: payload,
                successCount: 0,
                errorCount: 0,
                pendingCount: 0,
                checkCount: 0
            }])
        }
        catch (error) { console.error('Error in extending ewb:', error) }
    }

    const handleDownloadExcel = () => {
        const newArray = requests[0]?.response?.items.map(({
            ewb,
            wbn,
            client,
            vehicleNo,
            validTill,
            currentPlace,
            currentPinCode,
            status
        }) => ({
            EWB: ewb,
            WBN: wbn,
            CLIENT: client,
            LOCATION: currentPlace === "None" ? "" : `${currentPlace || ""} - ${currentPinCode || ""} - ${vehicleNo ? vehicleNo.toString().slice(0, 2) : "NONE"}`,
            VEHICLE: vehicleNo,
            PIN: currentPinCode === "560078" ? "" : currentPinCode,
            VALIDITY: validTill === "" ? "" : validTill,
            ERROR: validTill === "" ? status : ""
        }))
        const date = new Date().toLocaleString()
        const ws = utils.json_to_sheet(newArray)
        const wb = utils.book_new()
        utils.book_append_sheet(wb, ws, "Data")
        writeFileXLSX(wb, `Extend_${date}.xlsx`)
    }

    useEffect(() => {
        const handleStatusCheck = async (r) => {
            if (r.fullStatus === 'COMPLETED' || r.checkCount > 10000) return r
            try {
                dispatch({ type: Actions.ShowLoading })

                const url = `https://prod-1.e-waybill.in/api/v2/extend-status-bulk?request_id=${r.requestId}`
                const response = await fetch(url)
                const data = await response.json()

                r.request.items = r.request.items.map(x => {
                    const respItem = data.items.find(di => di.ewb === x.ewb)
                    return ({
                        ...x,
                        status: respItem?.status,
                        isSuccess: respItem?.status === 'SUCCESS',
                        isFail:
                            respItem?.status !== 'SUCCESS' &&
                            respItem?.status !== 'INIT' &&
                            (
                                respItem?.extending === false &&
                                respItem?.validTill === ""
                            ),
                        validTill: respItem?.validTill,
                        checkCount: r.checkCount + 1
                    })
                })
                const okCount = r.request.items.filter((item) => item.isSuccess).length
                const errorCount = r.request.items.filter((item) => item.isFail).length
                return {
                    ...r,
                    fullStatus: data.fullStatus,
                    response: data,
                    successCount: okCount,
                    pendingCount: r.request.items.length - okCount - errorCount,
                    errorCount: errorCount,
                    checkCount: r.checkCount + 1
                }
            }
            catch (error) { console.error('Error in checking status of expiring EWB:', error) }
            finally { dispatch({ type: Actions.HideLoading }) }
            return r
        }

        const interval = setTimeout(() => {
            const checkForStatus = async () => {
                const results = await Promise.all(requests
                    .map(async (r) => {
                        return await handleStatusCheck(r)
                    }))
                setRequests(results)
            }
            checkForStatus().then(() => {
                console.info("check completed....")
            })
        }, 5000)
        return () => clearInterval(interval)
    }, [requests, dispatch])

    return (
        <div className="py-2">
            <div className="row">
                <div className="col-lg-3 m-3">
                    <h4>Extend EWaybill</h4>
                    <div className="form-group mb-3">
                        <label htmlFor='credential' className="mb-2">Credential</label>
                        <select
                            className="form-select"
                            id="credential"
                            name="credential"
                            onChange={handleCredentialChange}
                        >
                            <option>Select</option>
                            {users.map((user, index) => {
                                return (
                                    <option key={index} value={user.gstNumber}>{user.gstNumber}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="file" className="mb-2">Select Excel or CSV File To Upload</label>
                        <input
                            className="form-control"
                            type="file"
                            name="file"
                            id="file"
                            onChange={handleFileChange}
                        />
                    </div>
                    <button
                        className="btn btn-primary mb-5"
                        disabled={isExtendButtonDisabled}
                        onClick={handleExtendEWB}>
                        Extend
                    </button>
                </div>
                <div className="col-lg-7 m-5">
                    <div className="d-flex justify-content-between my-3">
                        <div className="text-info">Total Items: {TotalCount}</div>
                        <div className="text-success">Success: {SuccessCount}</div>
                        <div className="text-danger">Errors: {ErrorCount}</div>
                        <div className="text-primary">Pending: {PendingCount}</div>
                        <div>
                            <button
                                className="btn btn-primary btn-sm"
                                onClick={handleDownloadExcel}>
                                Excel
                            </button>
                        </div>
                    </div>
                    <Table requests={requests}/>
                </div>
            </div>
        </div>
    )
}
export default ExtendEWB