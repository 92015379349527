import { ThreePulseCircles } from "../../../Animation/Animation"
import PartBMultiVehicleUpdateModal from "../../../Modals/partBMultiVehicleUpdateModal"
import PartBMultiVehicleUpdateSubTable from "./SubTable"

const PartBMultiVehicleUpdateTable = ({
    handleDeleteAllReqFromDB,
    requestsDB,
    handleModalState,
    handleUpdateVehicleApi,
    modalState,
    handleClearModalState,
    isModalUpdateButtonDisabled
}) => {
    return (
        <div className="overflow-x-auto mb-3">
            <table className="table table-sm">
                <thead>
                    <tr className="text-uppercase">
                        <th>EWaybill</th>
                        <th>QTY</th>
                        <th>From - To</th>
                        <th>
                            <button
                                type="button"
                                className="btn btn-danger btn-sm"
                                onClick={handleDeleteAllReqFromDB}>
                                Delete All
                            </button>
                        </th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        requestsDB?.map((request, index) => {
                            const a = request.response
                            return (
                                <tr key={index}>
                                    <td>{a.ewb}</td>
                                    <td>{a.qty} {a.uom}</td>
                                    <td>{a.from} - {a.to}</td>
                                    <td>
                                        <PartBMultiVehicleUpdateSubTable request={request} />
                                    </td>
                                    <td>
                                        <span className="btn-group">
                                            {
                                                <button type="button"
                                                    className="btn btn-sm btn-success"
                                                    disabled={a.vehicles.length < 1}
                                                >
                                                    <a
                                                        className="text-decoration-none text-white"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        href={`https://prod-1.e-waybill.in/api/e-waybill/multi-update-print?request_id=${request.requestId}`}
                                                    >
                                                        Print
                                                    </a>
                                                </button>
                                            }
                                            {
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-primary"
                                                    data-bs-toggle="modal"
                                                    onClick={handleClearModalState}
                                                    data-bs-target={`#addVehicleModal${index}`}
                                                    disabled={request.total >= request.request.qty}
                                                >
                                                    Add Vehicle
                                                </button>
                                            }
                                        </span>
                                    </td>
                                    <td>{a.status !== "COMPLETED" && <ThreePulseCircles />}</td>
                                    <td>
                                        <PartBMultiVehicleUpdateModal
                                            handleModalState={handleModalState}
                                            handleUpdateVehicleApi={handleUpdateVehicleApi}
                                            index={index}
                                            request={request}
                                            modalState={modalState}
                                            isModalUpdateButtonDisabled={isModalUpdateButtonDisabled}
                                        />
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}
export default PartBMultiVehicleUpdateTable