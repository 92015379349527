import { useContext, useEffect, useState, useMemo } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons"
import { getUserCredentials } from "../../API/GetUserCreds"
import { CredentialsContext } from "../../API/CredentialsContext"
import { Actions } from "../../Reducer/reducer"
const jsotp = require('jsotp')

const Settings = () => {
    const { state, dispatch } = useContext(CredentialsContext)
    const users = useMemo(() => state?.credentials || [], [state.credentials])
    const [creds, setCreds] = useState(users)
    const [newCredState, setNewCredState] = useState({
        gstNumber: "",
        userName: "",
        password: "",
        totp: ""
    })
    const isAdmin = localStorage.getItem('isAdmin')
    const [selectedCredential, setSelectedCredential] = useState({})
    const isSaveButtonDisabled =
        newCredState.gstNumber === "" ||
        newCredState.password === "" ||
        newCredState.totp === "" ||
        newCredState.userName === ""

    const handleState = (event) => {
        const { name, value } = event.target
        setNewCredState(prevState => (
            {
                ...prevState,
                [name]: value
            }
        ))
    }

    const handleSelectCredential = (index) => {
        setSelectedCredential(users[index])
        setNewCredState({
            gstNumber: users[index].gstNumber,
            userName: users[index].userName,
            password: users[index].password,
            totp: users[index].totpKey || ""
        })
    }

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleSaveNewCredential(event)
        }
    }

    const handleSaveNewCredential = async (event) => {
        event.preventDefault()
        try {
            dispatch({ type: Actions.ShowLoading })
            const payload = {
                ...selectedCredential,
                gstNumber: newCredState.gstNumber,
                userName: newCredState.userName,
                password: newCredState.password,
                totp: newCredState.totpKey
            }
            const authToken = localStorage.getItem('authToken')
            const response = await fetch('https://app.e-waybill.in/app/cred',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Auth-Token': authToken,
                    },
                    body: JSON.stringify(payload),
                })
            if (response.ok) {
                getUserCredentials(authToken).then(creds => {
                    dispatch({ type: Actions.UpdateCredentials, payload: creds })
                    dispatch({ type: Actions.HideLoading })
                })
            }
        }
        catch (error) { console.error('Error in saving credentials:', error) }
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCreds(users => {
                return users.map(u => {
                    const totp = u.totpKey ? jsotp.TOTP(u.totpKey, 60).now() : ""
                    const seconds = (60 - (new Date()).getSeconds())
                    return {
                        ...u,
                        otp: totp,
                        seconds: seconds,
                    }
                })
            })
        }, 1000)
        return () => clearInterval(intervalId)
    }, [])

    useEffect(() => {
        setCreds(users)
    }, [users])

    return (
        <form className="py-2" onSubmit={handleSaveNewCredential}>
            {
                isAdmin === "true" ?
                    <div className="row">
                        <div className="col-lg-3 m-3">
                            <h4>New Credential</h4>
                            <div className="form-group mb-3">
                                <label htmlFor='gstNumber' className="mb-2">GST Number</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    id="gstNumber"
                                    name="gstNumber"
                                    value={newCredState.gstNumber}
                                    onChange={handleState}
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor='userName' className="mb-2">Username</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    id="userName"
                                    name="userName"
                                    value={newCredState.userName}
                                    onChange={handleState}
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor='password' className="mb-2">Password</label>
                                <input
                                    className="form-control"
                                    type="password"
                                    id="password"
                                    name="password"
                                    value={newCredState.password}
                                    onChange={handleState}
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor='totp' className="mb-2">TOTP</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    id="totp"
                                    name="totp"
                                    value={newCredState.totp}
                                    onChange={handleState} />
                            </div>
                            <button
                                className="btn btn-primary mb-5"
                                onClick={handleSaveNewCredential}
                                disabled={isSaveButtonDisabled}
                                onKeyDown={handleKeyDown}
                                type="submit"
                            >
                                Save
                            </button>
                        </div>
                        <div className="col-lg-7 m-5">
                            <h4 className="col-3">Existing Credentials</h4>
                            <div className="overflow-x-auto mb-3">
                                <table className="table table-sm">
                                    <thead>
                                        <tr className="text-uppercase">
                                            <th>User Name</th>
                                            <th>Password</th>
                                            <th>GST Number</th>
                                            <th>OTP</th>
                                            <th>&nbsp</th>
                                            <th>&nbsp</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {creds.map((user, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{user.userName}</td>
                                                    <td>{user.password}</td>
                                                    <td>{user.gstNumber}</td>
                                                    <td>
                                                        {
                                                            user.otp &&
                                                            <>
                                                                <div>OTP</div>
                                                                <div>Valid For</div>
                                                            </>
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            user.otp &&
                                                            <>
                                                                <div>{user.otp}</div>
                                                                <div>{user.seconds} Seconds</div>
                                                            </>
                                                        }
                                                    </td>
                                                    <td
                                                        className="text-success"
                                                        onClick={handleSelectCredential.bind(this, index)}
                                                    >
                                                        <button
                                                            type="button"
                                                            className="btn btn-success"
                                                        >
                                                            <FontAwesomeIcon icon={faPenToSquare} />
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div> :
                    <h1>You do not have permission to view this page!</h1>
            }
        </form>
    )
}
export default Settings