import classNames from "classnames"

const Table = ({ data }) => {
    return (
        <table className="table table-borderless">
            <thead>
                <tr className="text-uppercase table-dark">
                    <th>SL.NO</th>
                    <th>GSTIN</th>
                    <th>Name</th>
                    <th>Address</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                {
                    data.map((item, index) => {
                        return (
                            <tr key={index} className={classNames({
                                'table-warning': !item.valid,
                                'table-success': item.valid
                            })}>
                                <td>{index + 1}</td>
                                <td>{item.gstNumber}</td>
                                <td>{item.name}</td>
                                <td>
                                    {item.address} {item.address2} {item.state === "NOT_AVAILABLE" ? "" : item.state} {item.pinCode}
                                </td>
                                <td>{item.valid ? "VALID" : "IN-VALID"}</td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    )
}
export default Table