import classNames from "classnames"
import { ThreePulseCircles } from "../../../Animation/Animation";

const Table = ({ requests }) => {
    return (
        <div className="overflow-x-auto mb-3">
            <table className="table table-bordered table-sm">
                <thead>
                    <tr className="text-uppercase">
                        <th>SL.NO</th>
                        <th>Ewaybill No</th>
                        <th>New Validity</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        requests[0]?.response.ewaybillNos?.map((ewbNo, index) => {
                            const errors = requests[0].response.errors
                            const success = requests[0].response.validity
                            const Status = requests[0].response.status
                            return (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            className="text-decoration-none"
                                            href={`https://ewaybillgst.gov.in/BillGeneration/EBPrint.aspx?cal=1&ewb_no=${ewbNo}`}
                                        >
                                            {ewbNo}
                                        </a>
                                    </td>
                                    <td>{success[ewbNo]}</td>
                                    <td className={classNames({
                                        "text-success": success[ewbNo],
                                        "text-danger": errors[ewbNo] !== "ALREADY_EXTENDED"
                                    })}>
                                        {
                                            (Status !== "" && Status !== "COMPLETED") ?
                                                <ThreePulseCircles /> :
                                                success[ewbNo] ?
                                                    "EXTENDED" :
                                                    errors[ewbNo]
                                        }
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}
export default Table