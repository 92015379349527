import classNames from "classnames"
import { PrintTableHeader } from "../../../Data/printTableHeader"
import { ThreePulseCircles } from "../../../Animation/Animation"
import { utils, writeFileXLSX } from 'xlsx'
import { useCallback } from "react"

const Table = ({ requests, printState }) => {

    const Response = requests[0]?.response
    const isExcelDownloadDisabled =
        requests[0]?.status === "INIT" ||
        requests[0]?.status === undefined

    const handleDownloadExcel = () => {
        const newArray = Response?.ewaybills.map(({
            generatedAt,
            ewaybill,
            consignorGst,
            consignorAddr,
            consignorPin,
            consigneeGst,
            consigneeAddr,
            consigneePin,
            invoiceNo,
            invoiceVal,
            invoiceDt,
            transporterId,
            validTill }) => ({
                EWB: ewaybill,
                CONSIGNOR_GST: consignorGst,
                CONSIGNOR_ADDRESS: consignorAddr,
                CONSIGNOR_PIN: consignorPin,
                CONSIGNEE_GST: consigneeGst,
                CONSIGNEE_ADDRESS: consigneeAddr,
                CONSIGNEE_PIN: consigneePin,
                INVOICE_NO: invoiceNo,
                INVOICE_VALUE: invoiceVal,
                INVOICE_DATE: invoiceDt,
                TRANSPORTER_ID: transporterId,
                GENRATED_AT: generatedAt,
                EXPIRY: validTill,
                MESSAGE: validTill ? "SUCCESS" : "ERROR",
            }))
        const ws = utils.json_to_sheet(newArray)
        const wb = utils.book_new()
        utils.book_append_sheet(wb, ws, "Data")
        const date = new Date().toLocaleString()
        writeFileXLSX(wb, `bulk-excel-${printState.credential.gstNumber}${date}.xlsx`)
    }
    
    return (
        <table className="table table-borderless table-sm table-striped mb-5">
            <thead>
                <tr className="text-uppercase table-dark">
                    {PrintTableHeader.map((title, index) => {
                        return <th key={index}>{title}</th>
                    })}
                    <th>
                        <div className="btn-group">
                            <button
                                className="btn btn-sm btn-success text-uppercase"
                                onClick={handleDownloadExcel}
                                disabled={isExcelDownloadDisabled}>
                                Excel
                            </button>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                className="btn btn-sm btn-info"
                                href={`https://api.e-waybill.in/api/e-waybill/bulk-print?request_id=${requests[0]?.requestId}`}>
                                Print All
                            </a>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                {Response?.ewaybillNos.sort((a, b) => b - a).map((item, index) => {
                    const r = Response?.ewaybills.sort((a, b) => b.ewaybillNo - a.ewaybillNo)
                    return (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item}</td>
                            <td>
                                {r[index]?.consignorGst} <br />
                                {r[index]?.consignorAddr}
                            </td>
                            <td>
                                {r[index]?.consigneeGst} <br />
                                {r[index]?.consigneeAddr}
                            </td>
                            <td>
                                {r[index]?.invoiceNo} <br />
                                {r[index]?.invoiceDt} <br />
                                {r[index]?.invoiceVal}
                            </td>
                            <td>{r[index]?.transporterId}</td>
                            <td>{new Date(r[index]?.generatedAt).toDateString()}</td>
                            <td>{new Date(r[index]?.validTill).toDateString()}</td>
                            <td className={classNames({
                                "text-success": r[index]?.valid,
                                "text-danger": !r[index]?.valid
                            })}>
                                {r[index]?.valid ? "Valid EWaybill" : r[index]?.status}
                            </td>
                            <td>
                                {requests[0].status !== "COMPLETED" ?
                                    <ThreePulseCircles /> :
                                    (r[index]?.valid ? "COMPLETED" : "ERROR")}
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </table >
    )
}
export default Table