const Footer = () => {
    
    const currentYear = new Date().getFullYear()

    return (
        <footer className="border border-top mt-5 p-3 text-secondary fixed-bottom bg-light">
            <div className="footer-flex">
                <div>&#169;	365Build Technologies (I) Pvt Ltd {currentYear}</div>
                <div>Email: <a className="text-primary text-decoration-none" href="mailto:support@365build.in">support@365Build.in</a></div>
                <div>Phone: <a className="text-primary text-decoration-none" href="tel:9591997072">+91 9591997072</a></div>
            </div>
        </footer>
    )
}
export default Footer