export async function getUserCredentials (authToken) {
    if (authToken) {
        try {
            const response = await fetch('https://app.e-waybill.in/app/cred', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Auth-Token': authToken,
                },
            });                
            if (response.ok) {
                return await response.json()
            }
        } catch (error) {
            console.error('Error:', error);
        }
       
    }
    return  [];
}