import React from 'react'
import { Navigate, useLocation } from 'react-router-dom';

export const RequireAuth = ({ children }) => {
    const auth = localStorage.getItem('authToken')
    const location = useLocation();
    if (!auth) {
        // if user not authenticated then send to login page
        return <Navigate
            to='/'
            replace
            state={{
                data: location.pathname
            }} />
    }
    return children;
}

